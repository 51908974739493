.SingleQuestionToTest {
    border: 1px solid black;
    border-radius: 5px;
    background-color: #efefefef;
    margin: 10px auto;
    padding: 0 10px 20px 10px;
}

.SingleQuestionToTest .answer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 0 20px;
}

.SingleQuestionToTest .answer label {
    margin-bottom: 20px;
}

.SingleQuestionToTest .answer label:last-child {
    margin-bottom: 0;
}
