.TestResult {
    margin: 15% auto 0 auto;
    text-align: center;
    border: 1px solid black;
    width: 50%;
    padding: 25px;
    border-radius: 5px;
    -webkit-box-shadow: 0 10px 50px 0 rgba(66, 68, 90, 1);
    -moz-box-shadow: 0 10px 50px 0 rgba(66, 68, 90, 1);
    box-shadow: 0 10px 50px 0 rgba(66, 68, 90, 1);
}

.TestResult h1 {
    font-size: 5.5vmin;
}

.TestResult p {
    font-size: 3vmin;
    margin-bottom: 7%;
}

