.MainView {
    text-align: center;
}

.Link {
    text-decoration: none;
    border: 1px solid black;
    color: black;
    display: block;
    width: 30%;
    padding: 1%;
    font-size: 3vmin;
    margin:  10px auto 0 auto;
    border-radius: 5px;
    text-align: center;
}

.logout--button {
    background-color: white;
    text-decoration: none;
    border: 1px solid black;
    color: black;
    display: block;
    width: 32%;
    padding: 1%;
    font-size: 2.5vmin;
    margin:  10% auto 0 auto;
    border-radius: 5px;
    text-align: center;
}

.logout--button {
    cursor: pointer;
}
