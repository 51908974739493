.PreviewQuestion {
    width: 80%;
    border: 1px solid black;
    text-align: center;
    justify-content: center;
    margin: 15px auto;
    border-radius: 15px;
    padding-bottom: 5px;
    background-color: #efefef;
}

.PreviewQuestion .question {
    font-size: 3vmin;
    margin-bottom: 0;
}

.PreviewQuestion .answer {
    margin-top: 0;
    font-size: 3.5vmin;
    font-weight: bold;
}

.PreviewQuestion .module {
    font-size: 1.8vmin;
}

.PreviewQuestion p {
    margin: 10px;
}


