.QuestionsView {
    text-align: center;
}

.QuestionsView h1 {
    font-size: 10vmin;
    margin-bottom: 2vmin;
}

.QuestionsView h2 {
    margin-top: 0;
    font-size: 6vmin;
}

.QuestionsView .Link {
    text-decoration: none;
    border: 1px solid black;
    color: black;
    display: block;
    width: 17%;
    padding: 1%;
    font-size: 2.5vmin;
    margin:  10px auto 0 auto;
    border-radius: 5px;
}

.QuestionsView .Link:last-child {
    margin-top: 30px;
}
