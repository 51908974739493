.AdminView {
    background-color: #efefef;
    height: 100vh;
}

header {
    padding: 10px 40px 10px 5%;
    display: flex;
    flex-direction: row;
    height: 10%;
    align-items: center;
    justify-content: space-between;
    background-color: #c7c7c7;
    margin-bottom: 20px;
}

header .logout--button {
    margin: 0;
    font-size: 2vmin;
    padding: 0;
    width: 10%;
    background-color: #c7c7c7   ;
}

.AdminView .menu {
    display: flex;
    flex-direction: column;
    width: 20%;
    margin-top: 2%;
}

.AdminView .menu .menu--option {
    display: flex;
    justify-content: center;
    width: 50%;
    margin: 10px auto;
    text-decoration: none;
    border: 1px solid black;
    border-radius: 5px;
    text-align: center;
    font-size: 2vmin;
}

.menu--option:hover {
    cursor: pointer;
}

.main--box {
    display: block;
    width: 75%;
    background-color: white;
    border: 1px solid #efefef;
    border-radius: 5px;
    -webkit-box-shadow: 0 9px 11px 0 rgba(66, 68, 90, 1);
    -moz-box-shadow: 0 9px 11px 0 rgba(66, 68, 90, 1);
    box-shadow: 0 9px 11px 0 rgba(66, 68, 90, 1);
    height: 95%;
}

.container {
    display: flex;
    flex-direction: row;
    height: 80%;
}

.search {
    margin-top: 10px;
    margin-left: 30px;
}

.admin-panel {
    margin: 30px;
    overflow: auto;
    height: 85%;
}

thead {
    font-size: 2vmin;
    font-weight: bold;
    text-align: left;
}

table thead th {
    font-size: 2.5vmin;
    padding-bottom: 10px;
}

table tbody tr {
    background-color: #efefef;
    width: 50%;
}

.user--preview table tbody td {
    padding-left: 5px;
    font-size: 1.5vmin;
    background-color: #efefef;
    width: 50%;
}

.question--preview table tbody td {
    padding-left: 5px;
    font-size: 1.5vmin;
    background-color: #efefef;
    width: 40%;
}

table button {
    background-color: #efefef;
    border: 1px solid #efefef;
    border-radius: 5px;
    font-size: 2vmin;
}

table button:hover {
    cursor: pointer;
    background-color: #ababab;
}

table .admin--link {
    text-decoration: none;
    color: black;
    font-size: 2vmin;
    border-radius: 5px;
    border: 1px solid #efefef;
}

table .admin--link:hover     {
    cursor: pointer;
    background-color: #ababab;
}
